import * as React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import HomeBG from "../images/new-slider-home.jpg";
import { Helmet } from "react-helmet";
import Favicon from "../images/logos/Gogreen-favicon.png";

const indexPage = () => {
  return (
    <>
      <main
        class="relative object-cover"
        style={{ backgroundImage: `url(${HomeBG})` }}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>Go Green Credits | Welcome</title>

          <meta
            name="description"
            content="Go Green can provide green energy to customers in a multitude of ways from many renewable energy services and products to offsetting credits. Check out our services pages to learn more about how to go green! "
          />
          <link rel="icon" href={Favicon} />
        </Helmet>
        <div class="hidden md:block">
          <Header />
        </div>
        <div class="md:hidden z-10">
          <Header />
        </div>
        <div>
          <div class="hero pt-10 pb-16 px-4 md:px-0 md:pt-28 md:pb-40 items-center pb-2 lg:overflow-hidden">
            <div class="mx-auto max-w-7xl">
              <div class="flex justify-center items-center">
                <div class="md:w-9/12">
                  <h1 class="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                    Purchase Carbon Credits and make a Positive Impact Today…
                  </h1>
                  <p class="mt-3 text-base text-white sm:mt-8 sm:text-xl lg:text-lg xl:text-xl">
                    We believe that everyone has the power to positively impact
                    climate change and fight global warming. You can do your
                    part today by purchasing credits to offset the carbon
                    footprint of your home or business.
                  </p>{" "}
                </div>
                <div class="hidden lg:block pl-10 w-1/2  relative"></div>
              </div>
            </div>
          </div>
          <div class="hidden block md:items-center md:mx-auto md:grid md:grid-cols-3 lg:grid-cols-3">
            <a
              class="group bg-neonGreen hover:bg-darkOrange px-20 py-10"
              href="/offset-commercial"
            >
              <div class="max-w-xs mx-auto">
                <div class="flex justify-between items-center  pl-10">
                  <svg
                    class="flex justify-between h-12  text-white group-hover:text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M3 2.25a.75.75 0 000 1.5v16.5h-.75a.75.75 0 000 1.5H15v-18a.75.75 0 000-1.5H3zM6.75 19.5v-2.25a.75.75 0 01.75-.75h3a.75.75 0 01.75.75v2.25a.75.75 0 01-.75.75h-3a.75.75 0 01-.75-.75zM6 6.75A.75.75 0 016.75 6h.75a.75.75 0 010 1.5h-.75A.75.75 0 016 6.75zM6.75 9a.75.75 0 000 1.5h.75a.75.75 0 000-1.5h-.75zM6 12.75a.75.75 0 01.75-.75h.75a.75.75 0 010 1.5h-.75a.75.75 0 01-.75-.75zM10.5 6a.75.75 0 000 1.5h.75a.75.75 0 000-1.5h-.75zm-.75 3.75A.75.75 0 0110.5 9h.75a.75.75 0 010 1.5h-.75a.75.75 0 01-.75-.75zM10.5 12a.75.75 0 000 1.5h.75a.75.75 0 000-1.5h-.75zM16.5 6.75v15h5.25a.75.75 0 000-1.5H21v-12a.75.75 0 000-1.5h-4.5zm1.5 4.5a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75h-.008a.75.75 0 01-.75-.75v-.008zm.75 2.25a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75v-.008a.75.75 0 00-.75-.75h-.008zM18 17.25a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75h-.008a.75.75 0 01-.75-.75v-.008z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <div class="mx-auto text-3xl font-medium text-white group-hover:text-white">
                    Business
                  </div>
                </div>
              </div>
            </a>
            <a
              class="group bg-fadedGreen hover:bg-darkOrange px-20 py-10"
              href="/offset-residential"
            >
              <div class="max-w-xs mx-auto">
                <div class="flex justify-between items-center pr-10 ">
                  <svg
                    class="flex justify-between h-12 text-white group-hover:text-white "
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M19.006 3.705a.75.75 0 00-.512-1.41L6 6.838V3a.75.75 0 00-.75-.75h-1.5A.75.75 0 003 3v4.93l-1.006.365a.75.75 0 00.512 1.41l16.5-6z"></path>
                    <path
                      fill-rule="evenodd"
                      d="M3.019 11.115L18 5.667V9.09l4.006 1.456a.75.75 0 11-.512 1.41l-.494-.18v8.475h.75a.75.75 0 010 1.5H2.25a.75.75 0 010-1.5H3v-9.129l.019-.006zM18 20.25v-9.565l1.5.545v9.02H18zm-9-6a.75.75 0 00-.75.75v4.5c0 .414.336.75.75.75h3a.75.75 0 00.75-.75V15a.75.75 0 00-.75-.75H9z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <div class="mx-auto text-3xl font-medium text-white group-hover:text-white">
                    Residential
                  </div>
                </div>
              </div>
            </a>
            <a
              class="group bg-neonGreen hover:bg-darkOrange px-20 py-10"
              href="/offset-travel"
            >
              <div class="max-w-xs mx-auto">
                <div class="flex justify-between items-center pl-5 pr-20">
                  <svg
                    class="flex justify-between  h-12 text-white group-hover:text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM8.547 4.505a8.25 8.25 0 1011.672 8.214l-.46-.46a2.252 2.252 0 01-.422-.586l-1.08-2.16a.414.414 0 00-.663-.107.827.827 0 01-.812.21l-1.273-.363a.89.89 0 00-.738 1.595l.587.39c.59.395.674 1.23.172 1.732l-.2.2c-.211.212-.33.498-.33.796v.41c0 .409-.11.809-.32 1.158l-1.315 2.191a2.11 2.11 0 01-1.81 1.025 1.055 1.055 0 01-1.055-1.055v-1.172c0-.92-.56-1.747-1.414-2.089l-.654-.261a2.25 2.25 0 01-1.384-2.46l.007-.042a2.25 2.25 0 01.29-.787l.09-.15a2.25 2.25 0 012.37-1.048l1.178.236a1.125 1.125 0 001.302-.795l.208-.73a1.125 1.125 0 00-.578-1.315l-.665-.332-.091.091a2.25 2.25 0 01-1.591.659h-.18c-.249 0-.487.1-.662.274a.931.931 0 01-1.458-1.137l1.279-2.132z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <div class="mx-auto text-3xl font-medium text-white group-hover:text-white ">
                    Travel
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="hidden features pt-14 px-4 pb-32">
          <div class="max-w-3xl pb-16 mx-auto text-center">
            <h2 class="text-4xl font-extrabold text-gray-900">
              Lorem ipsum dolor sit amet
            </h2>
            <p class="mt-4 text-lg text-gray-500">
              Ac euismod vel sit maecenas id pellentesque.
            </p>
          </div>
          <div class="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <dl class="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3">
              <div class="text-center">
                <dt>
                  <div class="flex items-center justify-center mx-auto h-48 w-48 rounded-full bg-emerald-800 text-white">
                    <svg
                      class="h-28 w-28 text-lime-300"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M1.615 11.375l-1.535-.986 5.399-.618 1.924 5.023-1.518-.961c-1.482 2.402-2.843 5.215-1.979 7.896-2.503-2.122-3.906-3.753-3.906-5.723 0-1.422.924-3.339 1.615-4.631zm4.057 5.625c-.763.931-1.002 2.887-.753 4.071.114.542.592.929 1.147.929h4.934v-5h-5.328zm10.706 5.176v1.824l-3.37-4.263 3.37-4.282v1.797c2.823-.005 5.798-.333 7.622-2.479-.484 3.246-1.131 5.298-2.805 6.336-1.209.748-3.354.973-4.817 1.067zm2.672-6.413c1.193.158 2.982-.669 3.857-1.505.401-.383.478-.993.186-1.464l-2.6-4.192-4.25 2.634 2.807 4.527zm-.937-11.561l1.61-.859-2.097 5.014-5.323-.772 1.579-.856c-1.372-2.467-3.162-5.028-5.925-5.583 1.904-.711 3.438-1.146 4.737-1.146 2.238 0 3.54 1.265 5.419 4.202zm-6.889.795c-.44-1.12-2.031-2.285-3.186-2.645-.529-.165-1.1.063-1.371.547l-2.408 4.307 4.364 2.441 2.601-4.65z" />
                    </svg>
                  </div>
                  <p class="mt-5 text-xl leading-6 font-medium text-gray-900">
                    Lorem ipsum dolor sit amet
                  </p>
                </dt>
                <dd class="mt-2 text-sm text-gray-500">
                  Lorem ipsum, dolor sit amet consectetur adipisicing.
                </dd>
              </div>
              <div class="text-center">
                <dt>
                  <div class="flex items-center justify-center mx-auto h-48 w-48 rounded-full bg-emerald-800 text-white">
                    <svg
                      class="h-28 w-28 text-lime-300"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M1.615 11.375l-1.535-.986 5.399-.618 1.924 5.023-1.518-.961c-1.482 2.402-2.843 5.215-1.979 7.896-2.503-2.122-3.906-3.753-3.906-5.723 0-1.422.924-3.339 1.615-4.631zm4.057 5.625c-.763.931-1.002 2.887-.753 4.071.114.542.592.929 1.147.929h4.934v-5h-5.328zm10.706 5.176v1.824l-3.37-4.263 3.37-4.282v1.797c2.823-.005 5.798-.333 7.622-2.479-.484 3.246-1.131 5.298-2.805 6.336-1.209.748-3.354.973-4.817 1.067zm2.672-6.413c1.193.158 2.982-.669 3.857-1.505.401-.383.478-.993.186-1.464l-2.6-4.192-4.25 2.634 2.807 4.527zm-.937-11.561l1.61-.859-2.097 5.014-5.323-.772 1.579-.856c-1.372-2.467-3.162-5.028-5.925-5.583 1.904-.711 3.438-1.146 4.737-1.146 2.238 0 3.54 1.265 5.419 4.202zm-6.889.795c-.44-1.12-2.031-2.285-3.186-2.645-.529-.165-1.1.063-1.371.547l-2.408 4.307 4.364 2.441 2.601-4.65z" />
                    </svg>
                  </div>
                  <p class="mt-5 text-xl leading-6 font-medium text-gray-900">
                    Lorem ipsum dolor sit amet
                  </p>
                </dt>
                <dd class="mt-2 text-sm text-gray-500">
                  Lorem ipsum, dolor sit amet consectetur adipisicing.
                </dd>
              </div>
              <div class="text-center">
                <dt>
                  <div class="flex items-center justify-center mx-auto h-48 w-48 rounded-full bg-emerald-800 text-white">
                    <svg
                      class="h-28 w-28 text-lime-300"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M1.615 11.375l-1.535-.986 5.399-.618 1.924 5.023-1.518-.961c-1.482 2.402-2.843 5.215-1.979 7.896-2.503-2.122-3.906-3.753-3.906-5.723 0-1.422.924-3.339 1.615-4.631zm4.057 5.625c-.763.931-1.002 2.887-.753 4.071.114.542.592.929 1.147.929h4.934v-5h-5.328zm10.706 5.176v1.824l-3.37-4.263 3.37-4.282v1.797c2.823-.005 5.798-.333 7.622-2.479-.484 3.246-1.131 5.298-2.805 6.336-1.209.748-3.354.973-4.817 1.067zm2.672-6.413c1.193.158 2.982-.669 3.857-1.505.401-.383.478-.993.186-1.464l-2.6-4.192-4.25 2.634 2.807 4.527zm-.937-11.561l1.61-.859-2.097 5.014-5.323-.772 1.579-.856c-1.372-2.467-3.162-5.028-5.925-5.583 1.904-.711 3.438-1.146 4.737-1.146 2.238 0 3.54 1.265 5.419 4.202zm-6.889.795c-.44-1.12-2.031-2.285-3.186-2.645-.529-.165-1.1.063-1.371.547l-2.408 4.307 4.364 2.441 2.601-4.65z" />
                    </svg>
                  </div>
                  <p class="mt-5 text-xl leading-6 font-medium text-gray-900">
                    Lorem ipsum dolor sit amet
                  </p>
                </dt>
                <dd class="mt-2 text-sm text-gray-500">
                  Lorem ipsum, dolor sit amet consectetur adipisicing.
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </main>
      <div class="about UET relative bg-gradient-to-b from-white to-neonGreen pt-10 pb-9">
        <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
          <div class="relative sm:py-16 lg:py-0">
            <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
              <div class="relative pb-10 rounded-2xl  overflow-hidden">
                <img
                  class="md:block w-full flex-none rounded-xl object-cover shadow-xl  lg:max-h-screen lg:max-w-lg flex-grow "
                  style={{ height: "80vh" }}
                  src="https://www.unitedenergytrading.com/wp-content/uploads/hero-team.png"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div class="md:mt-20 relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
            <div class="">
              <h2 class="text-3xl text-gray-800 font-extrabold tracking-tight sm:text-4xl">
                Your Carbon Offset Renewable Partner
              </h2>
              <div class="mt-6 text-gray-700 space-y-6">
                <p class="text-lg">
                  Carbon offsets are a crucial tool in mitigating climate
                  change, and we're here to empower you to make a positive
                  impact on the world. When you engage with us, you're taking a
                  significant step towards reducing your carbon footprint. We
                  understand that it's not always easy to eliminate emissions
                  entirely, so we offer you the opportunity to compensate for
                  your unavoidable carbon emissions through carefully selected
                  offset projects.
                </p>
                <p class="text-lg leading-7">
                  Our mission is to connect you with projects that not only
                  reduce greenhouse gas emissions but also contribute to
                  sustainable development in communities around the globe.
                  Whether it's reforestation efforts, renewable energy
                  installations, or carbon capture initiatives, your support
                  makes a tangible difference. By choosing our carbon offset
                  solutions, you become part of a global movement working
                  towards a more sustainable future.
                </p>
                <p class="text-lg leading-7">
                  We provide transparency, ensuring your contributions directly
                  fund impactful projects. Together, we can combat climate
                  change, protect vital ecosystems, and create a better world
                  for future generations. Join us in the fight against climate
                  change and help us help the world.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hidden bg-white mb-10">
        <div class="max-w-7xl mx-auto py-12 lg:px-8">
          <h1 class="text-center text-2xl tracking-tight font-medium text-gray-500">
            Companies that have offset their Carbon emissions with us...
          </h1>
          <div class="mt-6 flex justify-center">
            <div class="w-1/3 mx-auto py-8 px-8">
              <a class="flex" href="https://consultfgc.com/" target="blank">
                <img
                  class="hover:opacity-70"
                  src="https://consultfgc.netlify.app/static/ConsultFGC_Logo-1714096432cc4fd9a8653a11b0a67248.png"
                  alt="Workcation"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="w-1/3 mx-auto rounded-md ">
          <a
            href="/offset-commercial"
            class="flex items-center justify-center px-10 py-5 border border-transparent text-base font-medium rounded-md text-white bg-emerald-500 hover:bg-emerald-700 md:py-4 md:text-lg md:px-10"
          >
            Commercial Offsets
          </a>
        </div>
      </div>{" "}
      <Footer />
    </>
  );
};

export default indexPage;
